import React from 'react'
import { Icon, Tag } from 'rsuite';
import { useMediaQuery } from '../utils/hooks';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord } from '@fortawesome/free-brands-svg-icons';

import Logo from '../images/LogoSmall.png';

// ------------------------------------------------------- //

const Home = () => {

    const isMobile = useMediaQuery('(max-width: 992px)')

    // ------------------------------------------------------- //

    return (
        <div className="h-100">
            
            <span style={{visibility: "hidden"}}>.</span>

            <div className="text-center" style={{marginTop: isMobile? "6vh" : "8vh"}}>


                <span style={{color: "#f76b82", fontSize: isMobile ? 15 : 20, fontFamily: "'Titillium Web', sans-serif", letterSpacing: 5, fontWeight: 600}}> 
                    CREATE, BUILD &amp; PLAY 
                </span>

                <div style={{fontFamily: "'Titillium Web', sans-serif", fontSize: isMobile ? 70 : 100, lineHeight: 1, fontWeight: "bolder"}}>
                    ONEVICE
                </div>

                <div style={{marginTop: 28, marginBottom: 30}}>
                    <p className="font-alternate" style={{fontSize: isMobile ? 14 : 15, margin: "auto", width: isMobile ? "80vw" : "unset"}}>
                        Welcome, we operate actively in <span className="text-main" style={{fontWeight: 600}}>Vice&nbsp;City:&nbsp;Multiplayer</span>. <br />  <br />

                        <p style={{fontSize: isMobile ? 14 : 15}}>
                            We are known for popular servers such as <span className="text-main" style={{fontWeight: 600}}>Rob&nbsp;The&nbsp;Vehicle & The&nbsp;Demolition&nbsp;Wars</span> {!isMobile && <br />}
                            and providing <span className="text-main" style={{fontWeight: 600}}>absolutely&nbsp;free</span> channels for forum and server hosts. <br />
                        </p>
                    </p>
                </div>

                <Tag className="font-main cursor-pointer" style={{fontSize: isMobile ? 10 : 12, backgroundColor: "#6F86D4"}} onClick={() => window.open('https://discord.gg/8CZvw3x4d5')} >
                    <FontAwesomeIcon icon={faDiscord}/> Discord
                </Tag>
                
                <Tag className="font-main cursor-pointer" style={{fontSize: isMobile ? 10 : 12, backgroundColor: "#292929"}} onClick={() => window.open('http://forum.vcmp.net/')}>
                    <Icon icon="attribution" /> Forums
                </Tag>

                <div style={{marginTop: isMobile ? "4vh" : "3vh"}}>
                    <img src={Logo} alt="Logo"/>
                </div>

            </div>
        </div>
    )
}

export default Home
