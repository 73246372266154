import React from 'react'
import { Alert, Button, Drawer, Icon, Table } from 'rsuite';
import { useMediaQuery } from '../utils/hooks';

const { Column, HeaderCell, Cell} = Table;

// ------------------------------------------------------- //

const ServerInfoDrawer = ({isOpen, close, data, dispatch}) => {

    const isMobile = useMediaQuery('(max-width: 992px)');

    if(!data) {
        return <React.Fragment></React.Fragment>
    }

    // ------------------------------------------------------- //

    const handleFavorite = () => {

        if(data.isFavorite) {

            Alert.info('Server has been removed from your favorites', 4000);
            data.isFavorite = false;
            dispatch({Type: "REMOVE", IP: data.ip});
        }
        else {

            Alert.info('Server has been added to your favorites', 4000);
            data.isFavorite = true;
            dispatch({Type: "ADD", IP: data.ip});
        }
    }

    // ------------------------------------------------------- //
    
    return (
        <React.Fragment>

            <Drawer 
                show={isOpen}
                onHide={close}
                full={isMobile}
            >

                <Drawer.Header>
                    <Drawer.Title>
                        {data.icon1} {data.isFavorite ? <Icon icon="star" style={{color: "#f76b82"}}/> : ''} {data.name}
                    </Drawer.Title>
                </Drawer.Header>

                <Drawer.Body>
                    
                    <h6>Server Information</h6>

                    <div> <span style={{fontWeight: 600, fontSize: 15}}>IP: </span> {data.ip} </div>
                    <div> <span style={{fontWeight: 600, fontSize: 15}}>GameMode: </span> {data.mode} </div>
                    <div> <span style={{fontWeight: 600, fontSize: 15}}>Players: </span> {data.playercount}/{data.maxp} </div>

                    <h6 className="mt-4">Players</h6> 

                    <Table
                        height={500 * window.innerHeight / 900}
                        data={data.players}
                        headerHeight={0}
                        rowClassName="rs-custom-row"
                        rowHeight={35}
                        className="mt-3"
                        hover={false}
                    >
                        <Column width={200}>
                            <HeaderCell></HeaderCell>
                            <Cell dataKey="name" />
                        </Column>
                    </Table>

                    {!isMobile &&
                        <Button block color="blue" className="mt-4" disabled>
                            Launch
                        </Button>
                    }

                    <Button block color="red" className="mt-3" onClick={handleFavorite}>
                        {data.isFavorite ? 'Remove' : 'Set'} Favorite
                    </Button>

                </Drawer.Body>

                <Drawer.Footer>
                    
                </Drawer.Footer>

            </Drawer>

        </React.Fragment>
    )
}

export default ServerInfoDrawer;
