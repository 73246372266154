import React from 'react';
import { useLocation } from 'react-router';
import { Header, Icon, Nav, Navbar, Tooltip, Whisper } from 'rsuite';
import { useMediaQuery } from '../utils/hooks';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import { faProjectDiagram, faWindowRestore } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

// ------------------------------------------------------- //

const spanStyle = {
    fontSize: "15px",
    fontFamily: '"Titillium Web", sans-serif',
    fontWeight: 600
};

const iconStyle = {
    fontSize: "18px"
};

// ------------------------------------------------------- //

const Navigation = () => {

    const {pathname} = useLocation();

    const isActive = (nav) => {
        return pathname === nav;
    }

    const isMobile = useMediaQuery('(max-width: 992px)');

    const CustNavItem = React.forwardRef((props, ref) => (
        <Nav.Item {...props}> <span style={{fontSize: "15px", fontWeight: 600}}>{props.children}</span> </Nav.Item>
    ))

    // ------------------------------------------------------- //

    if(!isMobile) {

        return (
            <Header>
    
                <Navbar appearance="subtle">
    
                    <Navbar.Body className="d-flex justify-content-center mt-2">
    
                        <Nav appearance="subtle" >

                            <Link 
                                className="link-unstyled" 
                                to="/" 
                                style={{...spanStyle, color: "unset"}}
                                component={CustNavItem}
                                active={isActive('/')}
                            > 
                                Home 
                            </Link>

                            <Link 
                                className="link-unstyled" 
                                to="/hosting" 
                                style={{...spanStyle, color: "unset"}}
                                component={CustNavItem}
                                active={isActive('/hosting')}
                            > 
                                Hosting 
                            </Link>

                            <Link 
                                className="link-unstyled" 
                                to="/browser" 
                                style={{...spanStyle, color: "unset"}}
                                component={CustNavItem}
                                active={isActive('/browser')}
                            > 
                                Browser 
                            </Link>
    
                            <Link 
                                className="link-unstyled" 
                                to="/servers" 
                                style={{...spanStyle, color: "unset"}}
                                component={CustNavItem}
                                active={isActive('/servers')}
                            > 
                                Servers 
                            </Link>
    
                            <Nav.Item onSelect={() => {window.open("https://forum.vcmp.net/")}}>
                                <Whisper
                                    preventOverflow
                                    trigger="hover"
                                    speaker={<Tooltip>forum.vcmp.net</Tooltip>}
                                    placement="bottom"
                                >
                                    <span style={spanStyle}> Forums </span>
                                </Whisper>
                            </Nav.Item>
                            
                            <Nav.Item onSelect={() => {window.open("https://discord.gg/g8dK8DhYSg")}}>
                                <Whisper
                                    preventOverflow
                                    trigger="hover"
                                    speaker={<Tooltip>discord.gg/g8dK8DhYSg</Tooltip>}
                                    placement="bottom"
                                >
                                    <span style={spanStyle}> Discord </span>
                                </Whisper>
                            </Nav.Item>
                        </Nav>
    
                    </Navbar.Body>
                </Navbar>
    
            </Header>
        )
    } else {

        return(
                
            <Navbar appearance="subtle">
        
                <Navbar.Body className="d-flex justify-content-center mt-3">

                    <Nav>

                        <Link 
                            className="link-unstyled" 
                            to="/" 
                            style={{...spanStyle, color: "unset"}}
                            component={CustNavItem}
                            active={isActive('/')}
                        > 
                            <Icon icon="home" style={iconStyle}/>
                        </Link>

                        <Link 
                            className="link-unstyled" 
                            to="/hosting" 
                            style={{...spanStyle, color: "unset"}}
                            component={CustNavItem}
                            active={isActive('/hosting')}
                        > 
                            <FontAwesomeIcon icon={faProjectDiagram} style={iconStyle} />
                        </Link>

                        <Link 
                            className="link-unstyled" 
                            to="/browser" 
                            style={{...spanStyle, color: "unset"}}
                            component={CustNavItem}
                            active={isActive('/browser')}
                        > 
                            <FontAwesomeIcon icon={faWindowRestore} style={iconStyle} /> 
                        </Link>

                        <Nav.Item onSelect={() => {window.open("https://forum.vcmp.net/")}}>
                            <Icon icon="attribution" style={iconStyle}/>
                        </Nav.Item>
                        
                        <Nav.Item onSelect={() => {window.open("https://discord.gg/g8dK8DhYSg")}}>
                            <FontAwesomeIcon icon={faDiscord} style={iconStyle}/>
                        </Nav.Item>
                    </Nav>

                </Navbar.Body>
            </Navbar>
        )
    }
}

export default Navigation;