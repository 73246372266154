import React, { useState } from 'react';
import IconInfo from '../components/IconInfo';
import ServerInfoModal from '../components/ServerInfoModal';
import { useModalState } from '../utils/hooks';

import LogoCDM from '../images/LogoSmall.png';
import LogoRTV from '../images/LogoRTV.png';
import LogoTDW from '../images/LogoTDW.png';

// ------------------------------------------------------- //

const INFO_CDM = {
    name: "Casual Deathmatch",
    ip: "88.198.19.155:8191",
    ipAlt: "vcmp.net:8191",
    language: "Squirrel",
    mode: "Arena based deathmatch",

    description: ["Casual Deathmatch is a lightweight but feature-packed deathmatch server. The map is separated into 8 different arenas accessible by GUI, players spawn at a random position inside the arena. Chat is kept clutter free by using floating text and deathlist.",
                    "Accounts are independent of other OneVice Servers"],

    img: LogoCDM
};

const INFO_RTV = {
    name: "Rob The Vehicle",
    ip: "88.198.19.155:5192",
    ipAlt: "vcmp.net:5192",
    language: "SqMod",
    mode: "Team Deathmatch",

    description: ["Rob The Vehicle has been around in VCMP since 2017 developed initially by Rest. Due to the unique concept of carjacking, the server gained monumental popularity during its prime.",
                    "Two teams fight head to head, attackers need to rob and deliver the target vehicle to their checkpoint while the defenders use all their power to stop them.",
                    "Accounts are synced with OneVice's database."],

    img: LogoRTV,
    discord: "https://discord.gg/YcMKj9JUw8",
    forums: "https://rtv.vcmp.net/"
};

const INFO_TDW = {
    name: "The Demolition Wars",
    ip: "88.198.19.155:8192",
    ipAlt: "vcmp.net:8192",
    language: "SqMod",
    mode: "Team Deathmatch",

    description: ["Introduced recently, The Demolition Wars is a highly sophisticated TDM gamemode built around the concept of bomb planting and defusal.",
                    "Attackers plant and protect the bomb while the defenders attempt to defuse it before the time runs out.",
                    "Accounts are synced with OneVice's database."],

    img: LogoTDW,
    discord: "https://discord.gg/bvqTQfX",
    forums: "https://tdw.vcmp.net/"
};

// ------------------------------------------------------- //

const Servers = () => {

    const [server, setServer] = useState(INFO_CDM);
    const {open, isOpen, close} = useModalState();

    // ------------------------------------------------------- //

    const onClick = (server) => {
        switch(server) {
            case 'cdm':
                setServer(INFO_CDM);
                break;

            case 'rtv':
                setServer(INFO_RTV);
                break;

            case 'tdw':
                setServer(INFO_TDW);
                break;

            default:
                break;
        }

        open();
    }

    // ------------------------------------------------------- //

    return (
        <div className="text-center" style={{marginTop: "8vh"}}>
            <span style={{color: "#f76b82", fontSize: 16, fontFamily: "'Titillium Web', sans-serif", letterSpacing: 5, fontWeight: 600}}> 
                SERVERS BUILT AND RUN BY
            </span>

            <div style={{fontFamily: "'Titillium Web', sans-serif", fontSize: 80, lineHeight: 1, fontWeight: "bolder"}}>
                ONEVICE
            </div>

            <div style={{marginTop: "3vh"}}>
                <IconInfo 
                    onClick={() => onClick('rtv')}
                    Logo={LogoRTV}
                    name="Rob The Vehicle (IV)"
                    description="The same old carjacking TDM gamemode. Improved to pack intense combats and tactical vehicle theft."
                />

                <IconInfo 
                    onClick={() => onClick('tdw')}
                    Logo={LogoTDW}
                    name="The Demolition Wars"
                    description="Calculated bomb defusal gamemode packed into TDM with unique and ornate maps."
                />

                <IconInfo 
                    onClick={() => onClick('cdm')}
                    Logo={LogoCDM}
                    name="Casual Deathmatch"
                    description="CDM is a lightweight deathmatch server built indepedently of OneVice's account sync."
                />
            </div>


            <ServerInfoModal 
                data={server}
                close={close}
                isOpen={isOpen}
            />
        </div>
    )
}

export default Servers;
