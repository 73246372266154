import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon, IconButton } from 'rsuite';

// ------------------------------------------------------- //

const NotFound = () => {
    return (
        <div className="text-center mt-page">

            <div style={{fontSize: 50, fontWeight: "bold", fontFamily: "'Titillium Web', sans-serif", lineHeight: 1.2}}>404: Not Found</div>
            <span style={{fontSize: 24, fontFamily: "'Roboto Condensed', sans-serif"}}>It looks like you have lost your way</span>

            <div style={{marginTop: 30}}>

                <IconButton color="red" style={{width: "15vh"}} icon={<Icon icon="home" />}>
                    <Link to="/" style={{color: "unset"}} className="link-unstyled">Home</Link>
                </IconButton>
 
                <Button color="red" style={{width: "12vh", marginLeft: 10}} appearance="ghost" onClick={() => window.history.back()}>
                    <span className="font-main">Back</span>
                </Button>
            </div>

        </div>
    )
}

export default NotFound;
