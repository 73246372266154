import React from 'react';
import { Button } from 'rsuite';

// ------------------------------------------------------- //

const IconInfo = ({onClick, Logo, name, description}) => {

    const divStyle = {
        display: "inline-block", 
        position: "relative",
    
        backgroundImage: `url(${Logo})`, 
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
    
        width: 250, 
        height: 200
    }

    // ------------------------------------------------------- //

    return (
        <div style={divStyle} className="text-center mr-3">
            <Button style={{marginTop: 130, fontWeight: 600}} onClick={onClick}>
                {name}
            </Button>

            <div className="mt-2 font-alternate">
                {description}
            </div>
        </div>
    )
}

export default IconInfo;
