import React from 'react';
import { Table } from 'rsuite';

const { Column, HeaderCell, Cell} = Table;

// ------------------------------------------------------- //

const getWidth = (width) => {
    return width * window.innerWidth / 1680;
}

// ------------------------------------------------------- //

const DesktopTable = ({tableRef, data, tableState, handleSort, isLoading, setSelected, open, renderEmpty}) => {
    return (
        <Table
        ref={tableRef}
        height={600 * window.innerHeight / 900}
        data={data}
        sortColumn={tableState.sortColumn}
        sortType={tableState.sortType}
        onSortColumn={handleSort}
        loading={isLoading}
        onRowClick={data => {
            setSelected(data);
            open();
        }}
        headerHeight={45}
        renderEmpty={renderEmpty}
        style={{borderRadius: 10}}
        bordered
        >
            <Column width={getWidth(50)} fixed>
                <HeaderCell></HeaderCell>
                <Cell dataKey="icon1" />
            </Column>

            <Column width={getWidth(350)} sortable>
                <HeaderCell>Name</HeaderCell>
                <Cell dataKey="name" />
            </Column>

            <Column width={getWidth(50)}>
                <HeaderCell></HeaderCell>
                <Cell dataKey="icon2" />
            </Column>

            <Column width={getWidth(220)}>
                <HeaderCell>IP</HeaderCell>
                <Cell dataKey="ip" />
            </Column>

            <Column width={getWidth(150)} sortable>
                <HeaderCell>Players</HeaderCell>
                <Cell dataKey="playercount" />
            </Column>

            <Column width={getWidth(250)}>
                <HeaderCell>GameMode</HeaderCell>
                <Cell dataKey="mode" />
            </Column>

            <Column width={getWidth(150)} sortable>
                <HeaderCell>Status</HeaderCell>
                <Cell dataKey="status" />
            </Column>
            
        </Table>
    )
}

export default DesktopTable