import React from 'react';
import { Button, Modal, Tag } from 'rsuite';

// ------------------------------------------------------- //

const ServerInfoModal = ({isOpen, close, data}) => {
    return (
        <Modal show={isOpen} onHide={close}>

            <Modal.Header>
                <Modal.Title>{data.name}</Modal.Title>

                <Tag className="mt-2">{data.ip}</Tag>
                <Tag className="mt-2">{data.ipAlt}</Tag>
            </Modal.Header>

            <Modal.Body>

                <img src={data.img} alt="logo" style={{width: 80}}/>

                <h6 className="mt-4">Server Information</h6>

                <div> <span style={{fontWeight: 600, fontSize: 15}}>Language: </span> {data.language} </div>
                <div> <span style={{fontWeight: 600, fontSize: 15}}>GameMode: </span> {data.mode} </div>

                <h6 className="mt-4">Description</h6>
                <div className={data.discord || data.forums ? `mb-3` : ""}> 
                    {data.description.map(el => <div className="alt-margin">{el}</div>)} 
                </div>

                {data.discord &&
                <div><span style={{fontWeight: 600, fontSize: 15}}>Discord: </span> <a href={data.discord}>{data.discord}</a></div> }

                {data.forums &&
                <div><span style={{fontWeight: 600, fontSize: 15}}>Forums: </span> <a href={data.forums}>{data.forums}</a></div> }
            </Modal.Body>

            <Modal.Footer>
                <Button block color="blue" className="mt-4" disabled>
                    Launch
                </Button>
            </Modal.Footer>

        </Modal>
    )
}

export default ServerInfoModal;
