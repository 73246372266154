import React from 'react';
import { Tag, Tooltip, Whisper } from 'rsuite';
import { useMediaQuery } from '../utils/hooks';

// ------------------------------------------------------- //

const Hosting = () => {

    const isMobile = useMediaQuery('(max-width: 992px)');

    // ------------------------------------------------------- //

    return (
        <div className={`h-100 d-flex justify-content-center ${isMobile ? "text-center" : ""}`}>
            
            <div style={{width: isMobile ? "70vw" : "55vw"}}>
                
                <div>
                    <div style={{fontFamily: "'Titillium Web', sans-serif", fontSize: isMobile ? 30 : 50, lineHeight: 1.5, fontWeight: "bolder", marginTop: "6vh"}}>
                        <span className="text-main">Server </span> Hosting
                    </div>

                    <div>
                        <Tag style={{fontSize: isMobile ? 10 : 13}}>Pterodactyl</Tag> 
                        <Tag style={{fontSize: isMobile ? 10 : 13}}>SFTP Access</Tag> 
                        <Tag style={{fontSize: isMobile ? 10 : 13}}>Maximum Control</Tag>
                    </div>

                    <p className="mt-3 font-alternate" style={{fontSize: isMobile ? 14 : 16}}>
                        Always wanted to build and host a server of your own but couldn't? Now you can! <br />
                        {isMobile && <br />}  OneVice provides absolutely free hosting with pterodactyl webpanels to manage your slots.
                    </p>

                    {!isMobile &&
                    <div className="mt-3 font-alternate">
                        <div style={{fontWeight: "bold", marginBottom:  4}}>Some servers hosted by OneVice:</div>

                        <div className="text-main">

                            <Whisper
                                placement="bottomStart"
                                preventOverflow
                                trigger="hover"
                                speaker={<Tooltip>Port: 2222</Tooltip>}
                            >
                                <span style={{fontSize: 12, marginRight: 8}}>Kill The Vito</span>
                            </Whisper>

                            <Whisper
                                placement="bottomStart"
                                preventOverflow
                                trigger="hover"
                                speaker={<Tooltip>Port: 5195</Tooltip>}
                            >
                                <span style={{fontSize: 12, marginRight: 8}}>Battlefield Kill Confirmed</span>
                            </Whisper>

                            <Whisper
                                placement="bottomStart"
                                preventOverflow
                                trigger="hover"
                                speaker={<Tooltip>Port: 8000</Tooltip>}
                            >
                                <span style={{fontSize: isMobile ? 9 : 12}}>Leadership Wars</span>
                            </Whisper>

                        </div>
                    </div>
                    }
                    
                </div>

                <div className={isMobile ? "" : "text-right"}>
                    <div style={{fontFamily: "'Titillium Web', sans-serif", fontSize: isMobile ? 30 : 50, lineHeight: 1.5, fontWeight: "bolder", marginTop: "6vh"}}>
                        <span className="text-main">Web </span> Hosting
                    </div>

                    <div>
                        <Tag style={{fontSize: isMobile ? 10 : 13}}>Forums</Tag> 
                        <Tag style={{fontSize: isMobile ? 10 : 13}}>Domains</Tag> 
                        <Tag style={{fontSize: isMobile ? 10 : 13}}>Websites</Tag>
                    </div>

                    <p className="mt-3 font-alternate" style={{fontSize: isMobile ? 14 : 16}}>
                        We also provide various sorts of web hosting. <br />
                        {isMobile && <br />} Webportals, forums and subdomains for clans, servers and projects. 
                    </p>

                    {!isMobile &&
                    <div className="mt-3 font-alternate">
                        <div style={{fontWeight: "bold", marginBottom: 4}}>Some websites hosted by OneVice:</div>

                        <div className="text-main">

                            <Whisper
                                placement="bottomEnd"
                                preventOverflow
                                trigger="hover"
                                speaker={<Tooltip>rtclan.vcmp.net</Tooltip>}
                            >
                                <a href="http://rtclan.vcmp.net/" style={{fontSize: 12, marginRight: 8, color: "unset"}}>
                                    RT Forum
                                </a>
                            </Whisper>

                            <Whisper
                                placement="bottomEnd"
                                preventOverflow
                                trigger="hover"
                                speaker={<Tooltip>killconfirmed.vcmp.net</Tooltip>}
                            >
                                <a href="https://killconfirmed.vcmp.net/" style={{fontSize: 12, color: "unset"}}>
                                    Kill Confirmed
                                </a>
                            </Whisper>

                        </div>
                    </div>
                    }

                </div>

                <div className="font-alternate text-center" style={{fontSize: isMobile ? 14 : 16, marginTop: isMobile ? 60 : 35}}>
                    <a className="link-unstyled text-main" target="_blank" rel="noreferrer" href="https://forum.vcmp.net/index.php?/forum/47-onevice-hosting/">Click here</a> to be taken to the hosting section.
                    <br />
                    <span style={{fontSize: isMobile ? 10 : 13}}>* not all requests are entertained, please read the terms.</span>

                </div> 

            </div>

        </div>
    )
}

export default Hosting;
