import React from 'react';

// ------------------------------------------------------- //

const FooterComp = () => {
    return (
        <div className="font-main ml-2 mb-2" style={{fontSize: 12}}>
            managed by <span className="font-bolder">Spiller & Berkay Khan</span> 
        </div>
    )
}

export default FooterComp;
