import { useCallback, useEffect, useReducer, useState } from "react";

export const useMediaQuery = query => {
    const [matches, setMatches] = useState(
        () => window.matchMedia(query).matches
    );
  
    useEffect(() => {
        const queryList = window.matchMedia(query);
        setMatches(queryList.matches);
  
        const listener = evt => setMatches(evt.matches);
  
        queryList.addListener(listener);
        return () => queryList.removeListener(listener);
    }, [query]);
  
    return matches;
};

// ------------------------------------------------------- //

function ServersReducer(PreviousState, Action) {
    
    switch (Action.Type) {
        case 'ADD': {
            return [...PreviousState, Action.IP];
        }

        case 'REMOVE': {
            return PreviousState.filter(IP => IP !== Action.IP);
        }

        default:
            return PreviousState;
    }
}

function PersistedReducer(Reducer, InitialState, Key) {

    const [State, Dispatch] = useReducer(Reducer, InitialState, Initial => {
        const Persisted = localStorage.getItem(Key);

        return Persisted ? JSON.parse(Persisted) : Initial;
    });

    useEffect(() => {
        localStorage.setItem(Key, JSON.stringify(State));
    }, [State, Key]);

    return [State, Dispatch];
}

export function useFavorites() {

    return PersistedReducer(ServersReducer, [], "favorites");
}

// ------------------------------------------------------- //

export function useModalState(defaultValue = false) {

    const [isOpen, setIsOpen] = useState(defaultValue);

    const open  = useCallback(() => setIsOpen(true), []);
    const close = useCallback(() => setIsOpen(false), []);

    return {isOpen, open, close};
}