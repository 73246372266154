import React, { useEffect, useState, useRef } from 'react';
import { Alert, Checkbox, Icon, IconButton, Input, InputGroup, Placeholder } from 'rsuite';
import DesktopTable from '../components/DesktopTable';
import MobileTable from '../components/MobileTable';

import ServerInfoDrawer from '../components/ServerInfoDrawer';
import { useFavorites, useMediaQuery, useModalState } from '../utils/hooks';

// ------------------------------------------------------- //

const Browser = () => {

    const isMobile = useMediaQuery('(max-width: 992px)');

    const tableRef = useRef(null);
    const [isLoading, setLoading] = useState(false);

    const [servers, setServers] = useState(null);
    const [officialServers, setOfficialServers] = useState(null);

    const [tableState, setState] = useState({});
    const [search, setSearch] = useState("");

    const [favorites, dispatchFavorites] = useFavorites();
    const {isOpen, open, close} = useModalState();
    const [selected, setSelected] = useState(null);

    // ------------------------------------------------------- //

    const handleSort = (sortColumn, sortType) => {
        setLoading(true);
      
        setTimeout(() => {

            setState(p => {
                return {
                    ...p,
                    sortColumn,
                    sortType
                }
            });

            setLoading(false);
        }, 500);
    }

    // ------------------------------------------------------- //
    
    const loadServers = () => {
        setLoading(true);

        // only fetch if we do not already have a list
        if(!officialServers) 
        {   
            fetch('https://vcmp.net:8080/http://master.vc-mp.org/servers/index.php')
            .then(response => response.json())
            .then(results => {
                if(!results.success || !results.servers) {
                    throw new Error("Loading official servers failed, please reload the browser!");
                }
                else {
                    const official = results.servers.filter(el => {
                        return el.is_official;
                    });

                    const official_ = official.map(el => {
                        return `${el.ip}:${el.port}`;
                    })

                    setOfficialServers(official_);
                }
            })
            .catch(err => {
                    Alert.error(err.message, 7000);
                    return;   
            });
        }

        // ------------------------------------------------------- //

        fetch('https://vcmp.net:8080/http://vcmp.net:3111/', {
            headers: {
                "origin": "https://onevice.vcmp.net"
            }
        })
        .then(response => response.json())
        .then(results => {

            if(!results.data) {
                throw new Error("Loading servers failed, please reload the browser!");
            }

            setServers(results.data);
            setLoading(false);
        })
        .catch(err => {
            Alert.error(err.message, 7000);
            return;   
        })
    }

    useEffect(loadServers, [officialServers]);

    // ------------------------------------------------------- //

    const excludeChecked = (checked) => {
        setState(p => {
            return {
                ...p,
                excludeLocked: checked
            }
        });

        tableRef.current.state.data = getData();
    }

    // ------------------------------------------------------- //

    const getData = () => {
        if(!servers || !officialServers) return {};

        const { sortColumn, sortType, excludeLocked } = tableState;

        let data = servers.map(el => {

            const isOfficial = officialServers.includes(el.connect);
            const isOV = el.connect.startsWith("88.198.19.155");
            const isFavorite = favorites.includes(el.connect);

            let status = " ";

            if(isOV) status = "OneVice Hosted";
            if(isOfficial) status = "Official";
            if(isOV && isOfficial) status = "Official (OV Hosted)";

            // ------------------------------------------------------- //

            return {
                name: el.name,
                ip: el.connect,
                maxp: el.maxplayers,
                locked: el.password,
                mode: el.raw.gamemode,
                playercount: el.raw.numplayers,
                players: el.players,
                status: status,
                isFavorite: isFavorite,
                icon1: el.password ? <Icon icon="lock" style={{color: "#F76B82"}}/> : '',
                icon2: isFavorite ? <Icon icon="star" style={{color: "#f76b82"}}/> : ''
            }
        });

        if(search.trim() !== "") {
            data = data.filter(el => {

                const term = search.toLowerCase();

                if(el.name.toLowerCase().indexOf(term) !== -1)
                    return true;

                if(el.ip.toLowerCase().indexOf(term) !== -1)
                    return true;

                let playerSearch = false;

                el.players.forEach(player => {
                    if(player) {
                        if(player.name.toLowerCase().indexOf(term) !== -1)
                            playerSearch = true;
                    }
                });

                if(playerSearch === true) return true;
                return false;
            })
        }

        if(excludeLocked) {
            data = data.filter(el => {
                return !el.locked;
            })
        }

        if (sortColumn && sortType) {

            return data.sort((a, b) => {

                let x = a[sortColumn];
                let y = b[sortColumn];

                if (typeof x === 'string') {
                    x = x.charCodeAt();
                }
                if (typeof y === 'string') {
                    y = y.charCodeAt();
                }
                if (sortType === 'asc') {
                    return x - y;
                } else {
                    return y - x;
                }
            });
        }

        return data.sort((a, b) => {

            if(a.isFavorite) {
                return b.isFavorite ? 0 : -1;
            }

            return 0;
        });
    }

    // ------------------------------------------------------- //

    const renderEmpty = () => {
        
        if(search.trim() !== "") {
            return (
                <div className="d-flex justify-content-center align-items-center h-100" style={{flexDirection: "column"}}>
                    <div className="mb-3">No results found</div>
                    <IconButton onClick={() => setSearch("")} icon={<Icon icon="close" />}>Clear search</IconButton>
                </div>
            )
        }

        return (
            <div className="d-flex justify-content-center align-items-center h-100" style={{flexDirection: "column"}}>
                <div className="mb-3">Failed to load serverlist</div>
                <IconButton onClick={() => loadServers()} icon={<Icon icon="reload" />}>Reload</IconButton>
            </div>
        )
    }


    // ------------------------------------------------------- //

    return (
        <div style={{width: isMobile ? "95vw" : "80vw", margin: "auto", marginTop: isMobile ? "3vh" : "9vh"}} className="font-alternate">

            {servers &&
                <React.Fragment>

                    {isMobile ? 
                    <MobileTable 
                        tableRef={tableRef}
                        tableState={tableState}
                        data={getData()}
                        renderEmpty={renderEmpty}
                        handleSort={handleSort}
                        isLoading={isLoading}
                        setSelected={setSelected}
                        open={open}/> 
                    : 
                    <DesktopTable 
                        tableRef={tableRef}
                        tableState={tableState}
                        data={getData()}
                        renderEmpty={renderEmpty}
                        handleSort={handleSort}
                        isLoading={isLoading}
                        setSelected={setSelected}
                        open={open}
                    />}

                    <InputGroup className={isMobile ? "mt-4" : "mt-3"}>
                        <InputGroup.Button>
                            <Icon icon="search" />
                        </InputGroup.Button>

                        <Input placeholder="Name, player name or IP address" value={search} onChange={(value) => {setSearch(value); tableRef.current.state.data = getData(); } }/>
                        
                        <Checkbox className="mr-3" onChange={(v, checked) => { excludeChecked(checked) } }> 
                            <span style={{fontSize: isMobile ? 10 : 12}}>Exclude&nbsp;locked</span>
                        </Checkbox>
                        
                        <InputGroup.Button onClick={() => loadServers()}>
                            <Icon icon="close" onClick={ () => { setSearch(''); tableRef.current.state.data = getData(); }}/>
                        </InputGroup.Button>

                        <InputGroup.Button onClick={() => loadServers()}>
                            Reload
                        </InputGroup.Button>

                    </InputGroup>
                </React.Fragment>
            }

            {!servers &&
                <div className="mt-page">
                    <Placeholder.Grid rows={10} columns={6} active />
                </div>
            }

            <ServerInfoDrawer 
                isOpen={isOpen}
                close={close}
                data={selected}
                dispatch={dispatchFavorites}
            />

        </div>
    )
}

export default Browser;
