import React, { useState } from 'react';
import { Route, Switch, useLocation } from 'react-router';
import { Container, Content, Footer, Loader } from 'rsuite';

import Background from './images/Background.png';

import NotFound from './pages/404';
import Home from './pages/Home';
import Hosting from './pages/Hosting';
import Browser from './pages/Browser';

import FooterComp from './components/Footer';
import Navigation from './components/Navbar';

import 'rsuite/dist/styles/rsuite-dark.css';
import './styles/main.scss';
import Servers from './pages/Servers';

// ------------------------------------------------------- //

function App() {

    const {pathname} = useLocation();
    const [isLoading, setLoading] = useState(true);

    // ------------------------------------------------------- //

    return (
        <React.Fragment>
            <Container className="h-100" style={{backgroundImage: pathname === '/browser' ? '' : `url(${Background})`, backgroundSize: "cover"}}>

                <img
                    alt="none"
                    style={{ display: 'none' }}
                    src={Background}
                    onLoad={() => setLoading(false)}
                />
                
                <Navigation />

                {!isLoading &&
                <React.Fragment>
                    <Content>
                        <Switch>

                            <Route exact path="/">
                                <Home />
                            </Route>

                            <Route exact path="/hosting">
                                <Hosting />
                            </Route>

                            <Route exact path="/browser">
                                <Browser />
                            </Route>

                            <Route exact path="/servers">
                                <Servers />
                            </Route>

                            <Route>
                                <NotFound />
                            </Route>

                        </Switch>
                    </Content>

                    <Footer>
                        <FooterComp />
                    </Footer>
                </React.Fragment>
                }

                {isLoading &&
                <div className="d-flex justify-content-center mt-page">
                    <Loader />
                </div>
                }

            </Container>
        </React.Fragment>
    );
}

export default App;
